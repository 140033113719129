import { GetStaticProps, NextPage } from 'next';
import { getPageData, getSiteData } from '@Lib/data-access/private';
import { SiteDataEntry } from '@Lib/types/design-types';
import HomePageSection from 'components/organisms/HomePageSection';
import HtmlHead from 'components/templates/HtmlHead';

interface HomePageProps {
  siteData: SiteDataEntry;
  pageData: any;
}

export const getStaticProps: GetStaticProps = () => {
  const props = {
    ...getSiteData().props,
    ...getPageData('home')().props,
  };

  return {
    props,
  };
};

const HomePage: NextPage<HomePageProps> = (props) => {
  const { pageData } = props;
  return (
    <>
      <HtmlHead
        url="https://www.crowntowncakes.com"
        title="Home"
        description="Crown Town Cakes specializes in made-to-order custom cakes for any occasion. Crown Town Cakes services Palm Springs, California and surrounding areas."
      />
      <HomePageSection data={pageData} />
    </>
  );
};

export default HomePage;
