import type { FC } from 'react';
import { TileData } from '@Lib/types/design-types';
import ContentfulNextImage from 'components/atoms/ContentfulNextImage';
import LinkButton from 'components/atoms/LinkButton';

export interface TileWithCallToActionListProps {
  tiles: TileData[];
}

export const TileWithCallToActionList: FC<TileWithCallToActionListProps> = (
  props
) => {
  const { tiles } = props;

  return (
    <div className="container bg-white">
      <div className="py-6">
        <div className="text-center">
          <h2>Unique Custom Cakes</h2>
          <p>We specialize in creating custom cakes for any occasion.</p>
        </div>
        <div className="grid gap-6 p-6 md:grid-cols-1 lg:grid-cols-3">
          {tiles.map((tile) => {
            return (
              <div
                key={tile.id}
                className="grid grid-rows-[min-content_1fr_min-content] bg-white shadow-xl"
              >
                <div className="relative h-80 lg:h-48 ">
                  <ContentfulNextImage
                    type="gallery"
                    image={tile.img}
                    className="object-cover object-top"
                  />
                </div>
                <div className="overflow-hidden p-6">
                  <h5>{tile.title}</h5>
                  <p>{tile.text}</p>
                </div>
                {tile.link ? (
                  <div className="p-4">
                    <LinkButton
                      color="cyan"
                      href={tile.link.href}
                      className="uppercase"
                    >
                      {tile.link.text}
                    </LinkButton>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
