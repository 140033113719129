import type { FC } from 'react';
import { CardData } from '@Lib/types/design-types';
import { ContentfulNextImage } from 'components/atoms/ContentfulNextImage/ContentfulNextImage';
import LinkButton from 'components/atoms/LinkButton';

export interface CardBannerProps {
  data: {
    card: CardData;
  };
}

const CardContent: FC<{
  card: CardData;
}> = (props) => {
  const {
    card: { content, link },
  } = props;
  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="text-center">
        {content.map((c, idx) => {
          switch (c.type) {
            case 'eyebrow': {
              return (
                <h3 key={idx} className="uppercase">
                  {c.value}
                </h3>
              );
            }
            case 'title': {
              return <h2 key={idx}>{c.value}</h2>;
            }
            case 'text': {
              return <p key={idx}>{c.value}</p>;
            }
          }
          return null;
        })}
      </div>
      {link ? (
        <div className="not-prose">
          <LinkButton href={link.href} className="uppercase" color="cyan">
            {link.text}
          </LinkButton>
        </div>
      ) : null}
    </div>
  );
};

export const CardBanner: FC<CardBannerProps> = (props) => {
  const {
    data: {
      card,
      card: { img },
    },
  } = props;

  return (
    <div className="container">
      <div className="relative h-96">
        <ContentfulNextImage
          image={img}
          type="hero"
          className="overflow-hidden object-cover"
        />
        <div className="hidden h-full w-full grid-cols-1 place-content-end sm:relative sm:grid">
          <div className="m-auto w-11/12 rounded-b-none rounded-t-2xl bg-white/90 p-6 sm:w-4/5 lg:w-1/2 xl:w-1/3">
            <CardContent card={card} />
          </div>
        </div>
      </div>
      <div className="container inline-block bg-white p-6 sm:hidden">
        <CardContent card={card} />
      </div>
    </div>
  );
};

export default CardBanner;
