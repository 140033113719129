import { FC } from 'react';
import ContentfulNextImage from 'components/atoms/ContentfulNextImage';
import CardBanner from 'components/organisms/CardBanner';
import TileWithCallToActionList from 'components/organisms/TileWithCallToActionList';

interface HomePageProps {
  data: any;
}

const HomePageSection: FC<HomePageProps> = (props) => {
  const { data } = props;
  return (
    <>
      <CardBanner data={data.sections[0].data as any} />
      <div className="container py-16">
        <div className="mx-auto grid max-w-lg justify-center py-6 text-center">
          <h2>Signature Designs</h2>
          <p>
            Over years of serving the Palm Springs community, we have developed
            these exciting signature designs that you can order by name.
          </p>
        </div>
        <div className="py-16">
          <div className="bg-cyan-500">
            <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
              <div className="-my-8 px-6">
                <div className="relative aspect-[16/9] h-96 w-full lg:aspect-1">
                  <ContentfulNextImage
                    image={{
                      alt: '',
                      src: '//images.ctfassets.net/g67phlm0yctp/3Wb02HkhNeln5Yubxu1nln/427fe801d92ade5362c03a2b1598a8b8/2023-04-04-sm-rnd-psdesert-wm.jpg',
                    }}
                    type="hero"
                    className="overflow-hidden rounded-2xl object-cover shadow-2xl lg:object-center"
                  />
                </div>
              </div>
              <div className="place-self-center p-6">
                <figure className="rounded-xl bg-gray-500/80 p-6">
                  <h2 className="text-white">Palm Springs Desert</h2>
                  <p className="text-white">
                    Striped with the colors of the desert sunset, and decorated
                    with saguaro cactuses and a variety of succulents.
                  </p>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="py-16">
          <div className="bg-cyan-500 pb-20 sm:pb-24 lg:pb-0">
            <div className="mx-auto grid max-w-7xl gap-8 lg:grid-cols-2 lg:px-8">
              <figure className="place-self-center rounded-xl bg-gray-500/80 p-6">
                <h2 className="text-white">Midcentury</h2>
                <p className="text-white">
                  Our celebration of midcentury modern design is a beautiful,
                  two-toned cake with an elegant breezeblock design. On top, a
                  golden star in the atomic style common in the era.
                </p>
              </figure>{' '}
              <div className="-my-8 w-full px-8">
                <div className="relative aspect-[16/9] h-96 lg:aspect-1">
                  <ContentfulNextImage
                    image={{
                      alt: '',
                      src: '//images.ctfassets.net/g67phlm0yctp/4syEVE1nsk0tltkd7GDdLM/c62b2ff7a68cdbd936eb97f50ac487ca/2023-03-13-md-rnd-midcentury-wm.jpg',
                    }}
                    type="hero"
                    className="overflow-hidden rounded-2xl object-cover shadow-2xl lg:object-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-16">
          <div className="bg-cyan-500 pb-20 sm:pb-24 lg:pb-0">
            <div className="mx-auto grid max-w-7xl gap-8 lg:grid-cols-2 lg:px-8">
              <div className="-my-8 w-full px-8">
                <div className="relative aspect-[16/9] h-96 lg:aspect-1">
                  <ContentfulNextImage
                    image={{
                      alt: '',
                      src: '//images.ctfassets.net/g67phlm0yctp/5NEamDvR2LOYKw2dFDpQW0/01eca65e42e4a6223a5fb337fb68181c/2023-05-04-md-rnd-flamingo-wm.jpg',
                    }}
                    type="hero"
                    className="overflow-hidden rounded-2xl object-cover shadow-2xl lg:object-center"
                  />
                </div>
              </div>
              <figure className="place-self-center rounded-xl bg-gray-500/80 p-6">
                <h2 className="text-white">Fabulous Flamingo</h2>
                <p className="text-white">
                  Fabulous Flaming is a signature design that incorporates a
                  large flamingo wearing an elegant necklace with our signature
                  saguaro cactuses, succulents, and desert flowers.
                </p>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <TileWithCallToActionList tiles={data.sections[2].data.tiles as any} />
    </>
  );
};

export default HomePageSection;
